.contentContainer {
    padding-top: 8px;

    .platformListContainer {
        max-width: 86vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        padding: 20px;
        background-color: white;
        border-radius: 4px;
        position: relative;
        gap: 8px;

        .listPlatform {
            display: flex;
            gap: 8px;
            overflow-x: auto;
            scroll-behavior: smooth;
            -ms-overflow-style: none;
            scrollbar-width: none;
            flex-grow: 1;
            padding: 0 10px;

            .platformItem {
                padding: 8px;
                border-width: 1px;
                border-radius: 4px;
                display: flex;
                justify-items: center;
                justify-content: space-between;
                min-width: 148px;

                &:hover {
                    border-color: #2C4B94;
                    background-color: #F1F5FF;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }

            @media (max-width: 1025px) {
                max-width: 867px;
            }
        }

        .btn_selectChannel {
            display: flex;
            justify-items: center;
            color: #2C4B94;
            border-color: #2C4B94;
            background-color: white;

            @media (max-width: 769px) {
                height: unset;
                span {
                    margin: 0 !important;
                }

                span:last-child {
                    display: none;
                }
            }
        }

        .btn_scroll {
            display: none;

            @media (max-width: 1200px) {
                display: block;
            }
        }
    }

    .listItemContainer {
        background-color: white;
        padding: 20px 20px 0 20px;
        margin-top: 10px;

        .itemContainer {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 20px 12px;
            background-color: white;
            border: 1px solid #EBEAED;
            border-radius: 4px;

            .itemContent {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;

                .itemChannelName {
                    width: 88%;
                    color: #2C4B94;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 16px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &Error {
                        color: red;
                    }
                }

                .itemPlatformLabel {
                    margin-left: 4px;
                    font-size: 11px;
                    line-height: 14px;
                    color: #6B7280;
                }
            }

            &.itemContainerError {
                box-shadow: 3px 0 0 0 red inset;
                border: 1px solid red;
            }
        }
    }
}